<template>
  <ZgyxLayout>
    <list></list>
  </ZgyxLayout>
</template>

<script>
import ZgyxLayout from '@src/components/zgyxLayout/ZgyxLayout.vue';
import list from './list.vue';
export default {
  name: 'quotaIndex',
  components: {
    ZgyxLayout,
    list
  }
};
</script>

<style lang="less" scoped></style>
