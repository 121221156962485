<template>
  <section class="list-wrap">
    <a-spin :spinning="pageLoading" tip="生成中......" size="large">
      <ZgyxSearchTablePagination
        ref="searchTablePagination"
        :formConfigs="[
          { type: 'input', label: '期数', key: 'nper' },
          { type: 'input', label: '项目名称', key: 'name' }
        ]"
        tableRowKey="projectId"
        :tableColumns="[
          { type: 'input', dataIndex: 'cityName', title: '地区' },
          { type: 'input', dataIndex: ['project', 'nper'], title: '期数' },
          { type: 'input', dataIndex: ['project', 'name'], title: '项目名称' },
          { type: 'input', dataIndex: 'reportingStartTime', title: '申报时间起' },
          { type: 'input', dataIndex: 'reportingEndTime', title: '申报时间止' },
          { type: 'custom', dataIndex: 'surplusDay', title: '剩余时间' },
          { type: 'custom', dataIndex: 'action', title: '操作', width: 250 }
        ]"
        :requestFunction="requestFunction"
      >
        <template #surplusDay="{ record }">
          <span v-if="record.surplusDay && record.surplusDay.split('天')[0] < 15" class="red">{{
            record.surplusDay
          }}</span>
          <span v-else>{{ record.surplusDay }}</span>
        </template>
        <template #action="{ record }">
          <template v-if="record.canWithdraw == 'yes'">
            <a-popconfirm
              placement="top"
              title="确认要撤回？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="recall(record)"
            >
              <a-button type="link" size="small"> 撤回 </a-button>
            </a-popconfirm>
          </template>
          <template v-if="record.canHandleType == 1 || record.canHandleType == 2">
            <a-button type="link" size="small" @click="toDetail(record)">
              {{ record.canHandleType == 1 ? '申报自评' : '申报自评修改' }}
            </a-button>
          </template>
          <template v-else-if="record.canHandleType == 4">
            <a-button type="link" size="small" @click="toDetail(record, 'revise')"> 驳回修改 </a-button>
          </template>
          <template v-else-if="record.canHandleType == 3">
            <a-button type="link" size="small" @click="toDetail(record, 'look')"> 查看详情 </a-button>
          </template>
          <template v-else>
            <a-button type="link" size="small" @click="toDetail(record, 'outTime', true)"> 查看详情 </a-button>
          </template>

          <template v-if="record.canSubmit == 'yes'">
            <a-popconfirm
              placement="top"
              title="确认提交到区吗？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleSubmitToCounty(record)"
            >
              <a-button type="link" size="small"> 提交 </a-button>
            </a-popconfirm>
          </template>

          <a-popconfirm
            placement="top"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleTaskSave(record)"
            v-if="record.canHandleType == 3"
          >
            <template #title> 确认生成台账? </template>
            <a-button type="primary" danger class="btn" size="small">生成台账</a-button>
          </a-popconfirm>
        </template>
      </ZgyxSearchTablePagination>
    </a-spin>
  </section>
</template>

<script>
// processStatus 1 社区自评  2驳回
import { mapGetters, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import {
  apiGetProject,
  taskSave,
  apiPostWithdraw,
  communityTotalSelfEvaluateSubmit,
  apiPostSelectionProjectResultCommunitySubmitDeclareToCounty,
  apiPostSelectionProjectResultCommunitySubmitAmendToCounty
} from '@src/apis/community';
import { ZgyxSearchTablePagination } from '@src/components';

export default {
  name: 'UserManagementsComponent',
  components: { ZgyxSearchTablePagination },
  data() {
    return {
      pageLoading: false
    };
  },
  computed: {
    ...mapGetters([]),
    requestFunction() {
      return apiGetProject;
    }
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {});
  },
  methods: {
    ...mapActions(['setActiveMenu']),
    async recall(record) {
      const _this = this;
      const res = await apiPostWithdraw({
        projectResultId: record.projectResultId
      });
      if (res.code === '00000') {
        message.success('操作成功');
        _this.$refs.searchTablePagination.getList();
      } else {
        // message.error('操作失败');
      }
    },
    toDetail(record, type, outTime) {
      let query = {};
      if (type == 'look') {
        query = { id: record.projectResultId, type: 'look' };
      } else if (type == 'revise') {
        query = { id: record.projectResultId, type: 'revise' };
      } else if (type == 'outTime') {
        query = { id: record.projectId, type: 'look', outTime };
      } else {
        query = { id: record.projectId, type: 'edit' };
      }
      this.setActiveMenu({
        path: '/assessment',
        name: '',
        query: query
      });
    },
    async handleTaskSave(record) {
      const _this = this;
      _this.pageLoading = true;
      const res = await taskSave({
        initParam: record.projectResultId,
        groupName: 'download',
        type: 100
      });
      _this.pageLoading = false;
      if (res.code === '00000') {
        message.success('操作成功');
        _this.$refs.searchTablePagination.getList();
      } else {
        // message.error('操作失败');
      }
    },
    //提交到区
    async handleSubmitToCounty(record) {
      const _this = this;
      let api = '';
      if (record.processStatus == 1) {
        //社区自评
        api = apiPostSelectionProjectResultCommunitySubmitDeclareToCounty;
      } else {
        api = apiPostSelectionProjectResultCommunitySubmitAmendToCounty;
      }
      const res = await api({
        projectResultId: record.projectResultId
      });
      if (res.code === '00000') {
        message.success('提交成功');
        _this.$refs.searchTablePagination.getList();
      } else {
        // message.error('提交失败');
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import url('~@src/styles/configs/index.less');
@import url('~@src/styles/mixins/index.less');

.list-wrap {
  padding: 24px;
  background: #f6f9fd;
  min-height: calc(100vh - 64px);
}
.red {
  color: red;
}
</style>
